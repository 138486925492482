import React from "react";
import {
  ArrayInput,
  Create,
  DateTimeInput,
  DateInput,
  Edit,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useCreateController,
  useEditController,
  minValue,
  number,
  required,
  useTranslate,
  Show,
  SimpleShowLayout,
  SelectField,
  ArrayField,
  Datagrid,
  NumberField,
  DateField,
} from "react-admin";
import { AttachmentFileField, AttachmentFileInput } from "../components/AttachmentFile";
import {
  createDeliveryStatusChoices,
  createDeliveryTypeChoices,
  createHacobellPreferredVehicleChoices,
  createHacobellStatuses,
  createHasProducerReviewed,
  createIsExceptionFlow,
} from "../components/choices";
import { DelivererForm } from "../components/DeliveryForm";
import { IndividualDeliveryToolbar } from "../components/IndividualToolbar";
import { UndeletableToolbar } from "../components/UndeletableToolbar";

import { Grade } from "../api/common";
import { useSpotContractAmend } from "../hooks/useAmend";
import { useSpotContractUnassignedDeliveryContents } from "../hooks/useUnassignedDeliveryContents";
import { validateMaxLengthText, validateMobilePhoneText, validateRequired } from "../utils/validator";
import { getValidateIndividualDelivery, getValidateDateIsAfterHandoverAt } from "../utils/amend_validator";
import { PackageCountPerDealField } from "../components/PackageCountPerDeal";
import { UnassignedDeliveryContentTable } from "../components/UnassignedDeliveryContentTable";
import { preventSendNull } from "../utils/api";
import { useWholesalerAddresses } from "../hooks/useWholesalerAddresses";
import { IndividualDelivery } from "../api/individual_deliveries";
import { calcPackageCountPerDeal } from "../utils/deal";
import { dateProps, dateTimeProps } from "../utils/locale";
import { HacobellPriceField } from "../components/HacobellPrice";
import { MemoField } from "../components/MemoField";
import { useLoginInfo } from "../hooks/useLoginInfo";
import { usePrefectures } from "../hooks/usePrefectures";
import { ReceiptTimingField } from "../components/ReceiptTimingField";
import { IntegerNumberInput } from "../components/IntegerNumberInput";
import { HiddenInput } from "../components/hidden";
import { useSpotContractDeal } from "../hooks/useDeal";

const initialValues = {
  attachments: [],
  delivery_address_id: undefined,
  delivery_contents: [],
  hacobell: {
    arrival_preferred_from: "",
    arrival_preferred_to: "",
    preferred_vehicle: "ten_ton",
    options: [],
  },
  self_delivery: {
    shipping_company_name: "",
    handover_date: "",
    handover_time: "",
    driver_name: "",
    driver_phone_number: "",
    car_number: "",
  },
};

export const SpotContractIndividualDeliveryShow = (props: { record?: IndividualDelivery }) => {
  const { record } = props;
  const translate = useTranslate();
  if (!record) {
    return null;
  }
  const { delivery_type } = record;
  const isHacobell = delivery_type === "hacobell";
  const isSelfDelivery = delivery_type === "self_delivery";
  return (
    <Show {...props} resource="spot_contract_individual_deliveries">
      <SimpleShowLayout>
        <SelectField source="status" choices={createDeliveryStatusChoices()} />
        <SelectField source="has_producer_reviewed" choices={createHasProducerReviewed()} />
        <ArrayField source="delivery_contents">
          <Datagrid>
            <TextField source="grade.name" emptyText={translate("ui.value.default_grade")} />
            <NumberField source="package_count" />
          </Datagrid>
        </ArrayField>
        <ReferenceField source="delivery_address_id" reference="wholesaler_addresses" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="delivery_type" choices={createDeliveryTypeChoices()} />
        {isHacobell && <SelectField source="hacobell.status" choices={createHacobellStatuses()} />}
        {isHacobell && <DateField source="hacobell.arrival_preferred_from" {...dateTimeProps} />}
        {isHacobell && <DateField source="hacobell.arrival_preferred_to" {...dateTimeProps} />}
        {isHacobell && (
          <SelectField source="hacobell.preferred_vehicle" choices={createHacobellPreferredVehicleChoices()} />
        )}
        {isHacobell && <DateField source="hacobell.handover_at" {...dateTimeProps} />}
        {isHacobell && <ReceiptTimingField source="receipt_timing" />}
        {isHacobell && <TextField source="hacobell.customer_name" />}
        {isHacobell && <TextField source="hacobell.shipping_company_name" />}
        {isHacobell && <TextField source="hacobell.shipping_company_phone_number" />}
        {isHacobell && <TextField source="hacobell.driver_name" />}
        {isHacobell && <TextField source="hacobell.driver_phone_number" />}
        {isHacobell && <MemoField source="hacobell.shipment_notes" />}
        {isSelfDelivery && <DateField source="self_delivery.handover_date" {...dateProps} />}
        {isSelfDelivery && <TextField source="self_delivery.handover_time" />}
        {isSelfDelivery && <ReceiptTimingField source="receipt_timing" />}
        {isSelfDelivery && <TextField source="self_delivery.shipping_company_name" />}
        {isSelfDelivery && <TextField source="self_delivery.driver_name" />}
        {isSelfDelivery && <TextField source="self_delivery.driver_phone_number" />}
        {isSelfDelivery && <TextField source="self_delivery.car_number" />}
        <TextField source="after_delivery_inspection_memo" component="pre" />
        <ArrayField source="after_delivery_inspection_attachments">
          <Datagrid>
            <AttachmentFileField />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const redirect = (basePath: string, id: number, record: any) => {
  return `/spot_contract_deals/${record.amend_id}/show/delivery`;
};

type Choice = {
  id: number;
  name: string;
};

function createGradeChoices(defaultGrade: Grade, inspectionResultGrades: Grade[] | undefined): Choice[] {
  if (inspectionResultGrades === undefined) {
    return [];
  }
  return inspectionResultGrades.map((hc) => {
    if (hc === null) {
      // 等級差額なしの場合
      return {
        // 仕様上はnullがidになるのを期待しているが、nullを設定すると未選択になるので保存時にnullに置き換える
        id: defaultGrade.id,
        name: "ui.value.default_grade",
      };
    }
    return {
      id: hc.id,
      name: hc.name,
    };
  });
}

const validateText = validateMaxLengthText(50);
const validatePackageCount = [required(), number(), minValue(1)];

// HACK: parseに関数を渡すとformの値が日付型がstringになる
const parseDateTime = (value: any) => value;

const defaultHacobellValues = {
  arrival_preferred_from: null,
  arrival_preferred_to: null,
  preferred_vehicle: "ten_ton",
  options: [],
};

const defaultSelfDeliveryValues = {
  handover_date: null,
  handover_time: "",
  driver_name: "",
  driver_phone_number: "",
  car_number: "",
  shipping_company_name: "",
};

const getTransform = (gradeId: number) => {
  return (record: any) => {
    return {
      ...record,
      delivery_contents: record.delivery_contents
        ? record.delivery_contents?.map((dc: any) => {
            return {
              grade_id: gradeId === dc.grade_id ? null : dc.grade_id,
              package_count: dc.package_count,
            };
          })
        : [],
      hacobell:
        record.delivery_type === "hacobell"
          ? {
              ...defaultHacobellValues,
              ...record.hacobell,
            }
          : null,
      self_delivery:
        record.delivery_type === "self_delivery"
          ? {
              ...defaultSelfDeliveryValues,
              ...record.self_delivery,
            }
          : null,
    };
  };
};

const validateShortText = validateMaxLengthText(20);
const validateDriverPhoneNumber = [...validateMaxLengthText(13), validateMobilePhoneText];

export const SpotContractIndividualDeliveryCreate = (props: any) => {
  const createProps = useCreateController(props);
  const { record } = createProps;
  const [loadingDeal, deal] = useSpotContractDeal(record?.amend_id);
  const [loadingAmend, amend] = useSpotContractAmend(record?.amend_id);
  const [loadingPrefectures, prefectures] = usePrefectures();
  const [loadingWholesalerAddresses, wholesalerAddresses] = useWholesalerAddresses();
  const [loadingUnassignedDeliveryContents, unassignedDeliveryContents] = useSpotContractUnassignedDeliveryContents(
    record?.amend_id
  );
  const [loadingLoginInfo, loginInfo] = useLoginInfo();
  const loading =
    loadingDeal ||
    loadingAmend ||
    loadingUnassignedDeliveryContents ||
    loadingWholesalerAddresses ||
    loadingLoginInfo ||
    loadingPrefectures;
  if (
    !record ||
    loading ||
    deal === null ||
    amend === null ||
    loginInfo === null ||
    unassignedDeliveryContents === null
  ) {
    return null;
  }
  const defaultAddress = wholesalerAddresses.find((wa) => wa.is_default);
  const defaultAddressId = defaultAddress?.id;
  const { decision_date } = deal;
  const { grade, handover_address, inspection_results, packaging_form } = amend;
  const transform = getTransform(grade.id);
  const inspectionResultGrades = inspection_results.map((inspectionResult) => inspectionResult.grade);
  const gradeChoices = createGradeChoices(
    grade,
    inspection_results?.map((hc) => hc.grade)
  );
  const formatGrade = (v: any) => {
    return v === null ? grade.id : v;
  };
  const isTruck = packaging_form.shipping_method === "truck";
  const deliveryTypeChoices = createDeliveryTypeChoices().filter((item) => {
    if (isTruck) {
      return true;
    }
    // トラックじゃない場合はハコベルは選択不可
    return item.id !== "hacobell";
  });
  const defaultDeliveryType = isTruck ? "hacobell" : "self_delivery";

  const { amount_in_kg, deal_amount } = packaging_form;
  const count = calcPackageCountPerDeal(amount_in_kg, deal_amount);
  const validateIndividualDelivery = getValidateIndividualDelivery(unassignedDeliveryContents, grade.id, count);
  const validateDateIsAfterHandoverAt = [getValidateDateIsAfterHandoverAt(decision_date)];
  return (
    <Create {...props} transform={transform}>
      <SimpleForm
        toolbar={
          <IndividualDeliveryToolbar
            disabledSaveAsDraft={false}
            saveAsNotifyDriverInfo={false}
            handoverAddress={handover_address}
            prefectures={prefectures}
            wholesalerAddresses={wholesalerAddresses}
          />
        }
        redirect={redirect}
        initialValues={initialValues}
        validate={validateIndividualDelivery}
      >
        <ReferenceField source="amend_id" reference="spot_contract_amends" link={false}>
          <TextField source="id" />
        </ReferenceField>

        <ReferenceField source="amend_id" reference="spot_contract_amends" label="生産者" link={false}>
          <TextField source="producer.company_name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="銘柄" link={false}>
          <TextField source="brand.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="等級" link={false}>
          <TextField source="grade.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="県産" link={false}>
          <TextField source="prefecture.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="地域" link={false}>
          <TextField source="region.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="包装形態" link={false}>
          <TextField source="packaging_form.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="1口当たりの数量" link={false}>
          <PackageCountPerDealField />
        </ReferenceField>

        <UnassignedDeliveryContentTable
          unassignedDeliveryContents={unassignedDeliveryContents}
          inspectionResultGrades={inspectionResultGrades}
        />

        <ArrayInput source="delivery_contents">
          <SimpleFormIterator disableReordering={true}>
            <SelectInput source="grade_id" choices={gradeChoices} format={formatGrade} defaultValue={grade.id} />
            <IntegerNumberInput source="package_count" validate={validatePackageCount} />
          </SimpleFormIterator>
        </ArrayInput>

        <h3>運送手配内容</h3>
        <ReferenceInput source="delivery_address_id" reference="wholesaler_addresses" defaultValue={defaultAddressId}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="delivery_type" choices={deliveryTypeChoices} defaultValue={defaultDeliveryType} />

        <ReferenceField
          source="amend_id"
          reference="spot_contract_deals"
          label="resources.spot_contract_individual_deliveries.fields.decision_date"
          link={false}
          addLabel={true}
        >
          <DateField source="decision_date" />
        </ReferenceField>

        <DelivererForm deliverer="hacobell">
          <TextInput source="hacobell.customer_name" defaultValue={loginInfo.wholesaler.name} validate={validateText} />
          <DateTimeInput
            source="hacobell.arrival_preferred_from"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
          />
          <DateTimeInput
            source="hacobell.arrival_preferred_to"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
          />
          <SelectInput source="hacobell.preferred_vehicle" choices={createHacobellPreferredVehicleChoices()} />
          <HacobellPriceField packagingForm={amend.packaging_form} handoverAddress={amend.handover_address} />
          <TextInput
            source="hacobell.shipment_notes"
            parse={preventSendNull}
            validate={validateMemo}
            fullWidth
            multiline
            style={{ width: "100%" }}
          />
        </DelivererForm>

        <DelivererForm deliverer="self_delivery">
          <DateInput
            source="self_delivery.handover_date"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
          />
          <TextInput source="self_delivery.handover_time" parse={preventSendNull} validate={validateShortText} />
          <TextInput source="self_delivery.shipping_company_name" parse={preventSendNull} validate={validateText} />
          <TextInput source="self_delivery.driver_name" parse={preventSendNull} validate={validateText} />
          <TextInput
            source="self_delivery.driver_phone_number"
            parse={preventSendNull}
            validate={validateDriverPhoneNumber}
          />
          <TextInput source="self_delivery.car_number" parse={preventSendNull} validate={validateShortText} />
        </DelivererForm>
      </SimpleForm>
    </Create>
  );
};

export const SpotContractIndividualDeliveryEdit = (props: any) => {
  const editProps: { record: IndividualDelivery } = useEditController(props);
  const { record } = editProps;
  const [loadingDeal, deal] = useSpotContractDeal(record?.amend_id);
  const [loadingAmend, amend] = useSpotContractAmend(record?.amend_id);
  const [loadingPrefectures, prefectures] = usePrefectures();
  const [loadingWholesalerAddresses, wholesalerAddresses] = useWholesalerAddresses();
  const [loadingUnassignedDeliveryContents, unassignedDeliveryContents] = useSpotContractUnassignedDeliveryContents(
    record?.amend_id
  );
  const loading =
    loadingDeal ||
    loadingAmend ||
    loadingUnassignedDeliveryContents ||
    loadingPrefectures ||
    loadingWholesalerAddresses;
  if (!record || loading || deal === null || amend === null || unassignedDeliveryContents === null) {
    return null;
  }
  const { status } = record;
  const isDraft = status === "draft";
  const isPublished = status === "waiting_for_inspection";
  const { decision_date } = deal;
  const { grade, handover_address, inspection_results, packaging_form } = amend;
  const transform = getTransform(grade.id);
  const inspectionResultGrades = inspection_results.map((inspectionResult) => inspectionResult.grade);
  const gradeChoices = createGradeChoices(
    grade,
    inspection_results?.map((hc) => hc.grade)
  );
  const formatGrade = (v: any) => {
    return v === null ? grade.id : v;
  };
  const isTruck = packaging_form.shipping_method === "truck";
  const deliveryTypeChoices = createDeliveryTypeChoices().filter((item) => {
    if (isTruck) {
      return true;
    }
    // トラックじゃない場合はハコベルは選択不可
    return item.id !== "hacobell";
  });

  const { amount_in_kg, deal_amount } = packaging_form;
  const count = calcPackageCountPerDeal(amount_in_kg, deal_amount);
  const validateIndividualDelivery = isDraft
    ? getValidateIndividualDelivery(unassignedDeliveryContents, grade.id, count)
    : null;
  const validateDateIsAfterHandoverAt = isDraft ? [getValidateDateIsAfterHandoverAt(decision_date)] : [];
  return (
    <Edit {...props} undoable={false} transform={transform}>
      <SimpleForm
        toolbar={
          <IndividualDeliveryToolbar
            disabledSaveAsDraft={!isDraft}
            saveAsNotifyDriverInfo={isPublished}
            handoverAddress={handover_address}
            prefectures={prefectures}
            wholesalerAddresses={wholesalerAddresses}
          />
        }
        validate={validateIndividualDelivery}
        redirect={redirect}
      >
        <HiddenInput source="status" />
        <TextField source="id" />
        <ReferenceField source="amend_id" reference="spot_contract_amends" link={false}>
          <TextField source="id" />
        </ReferenceField>

        <ReferenceField source="amend_id" reference="spot_contract_amends" label="生産者" link={false}>
          <TextField source="producer.company_name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="銘柄" link={false}>
          <TextField source="brand.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="等級" link={false}>
          <TextField source="grade.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="県産" link={false}>
          <TextField source="prefecture.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="地域" link={false}>
          <TextField source="region.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="包装形態" link={false}>
          <TextField source="packaging_form.name" />
        </ReferenceField>
        <ReferenceField source="amend_id" reference="spot_contract_amends" label="1口当たりの数量" link={false}>
          <PackageCountPerDealField />
        </ReferenceField>

        <UnassignedDeliveryContentTable
          unassignedDeliveryContents={unassignedDeliveryContents}
          inspectionResultGrades={inspectionResultGrades}
        />

        <ArrayInput source="delivery_contents">
          <SimpleFormIterator disableAdd={!isDraft} disableRemove={!isDraft} disableReordering={true}>
            <SelectInput
              source="grade_id"
              choices={gradeChoices}
              format={formatGrade}
              defaultValue={grade.id}
              disabled={!isDraft}
            />
            <IntegerNumberInput source="package_count" validate={validatePackageCount} disabled={!isDraft} />
          </SimpleFormIterator>
        </ArrayInput>

        <h3>運送手配内容</h3>
        <ReferenceInput source="delivery_address_id" reference="wholesaler_addresses" disabled={!isDraft}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="delivery_type" choices={deliveryTypeChoices} disabled={!isDraft} />

        <ReferenceField
          source="amend_id"
          reference="spot_contract_deals"
          label="resources.spot_contract_individual_deliveries.fields.decision_date"
          link={false}
          addLabel={true}
        >
          <DateField source="decision_date" />
        </ReferenceField>

        <DelivererForm deliverer="hacobell">
          <TextInput source="hacobell.customer_name" validate={validateText} />
          <DateTimeInput
            source="hacobell.arrival_preferred_from"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
          />
          <DateTimeInput
            source="hacobell.arrival_preferred_to"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
          />
          <SelectInput
            source="hacobell.preferred_vehicle"
            choices={createHacobellPreferredVehicleChoices()}
            defaultValue="ten_ton"
          />
          <HacobellPriceField packagingForm={amend.packaging_form} handoverAddress={amend.handover_address} />
          <TextInput
            source="hacobell.shipment_notes"
            parse={preventSendNull}
            validate={validateMemo}
            fullWidth
            multiline
            style={{ width: "100%" }}
          />
        </DelivererForm>

        <DelivererForm deliverer="self_delivery">
          <DateInput
            source="self_delivery.handover_date"
            parse={parseDateTime}
            validate={validateDateIsAfterHandoverAt}
            disabled={!isDraft}
          />
          <TextInput source="self_delivery.handover_time" parse={preventSendNull} validate={validateShortText} />
          <TextInput source="self_delivery.shipping_company_name" parse={preventSendNull} validate={validateText} />
          <TextInput source="self_delivery.driver_name" parse={preventSendNull} validate={validateText} />
          <TextInput
            source="self_delivery.driver_phone_number"
            parse={preventSendNull}
            validate={validateDriverPhoneNumber}
          />
          <TextInput source="self_delivery.car_number" parse={preventSendNull} validate={validateShortText} />
        </DelivererForm>
      </SimpleForm>
    </Edit>
  );
};

const validateMemo = validateMaxLengthText(2048);

const validateAfterDeliveryInspection = (values: any) => {
  if (values.has_trouble && !values.after_delivery_inspection_memo) {
    return {
      after_delivery_inspection_memo: "validation.after_delivery_inspections.should_fill_reason",
    };
  }
  return null;
};

export const SpotContractAfterDeliveryInspectionEdit = (props: any) => {
  const transform = (record: any) => {
    if (record.has_trouble) {
      return {
        ...record,
        is_exception_flow: true,
      };
    }
    return {
      ...record,
      is_exception_flow: false,
      status: "done",
    };
  };

  return (
    <Edit {...props} undoable={false} transform={transform}>
      <SimpleForm toolbar={<UndeletableToolbar />} redirect={redirect} validate={validateAfterDeliveryInspection}>
        <TextField source="id" />
        <ReferenceField source="amend_id" reference="spot_contract_amends" link={false}>
          <TextField source="id" />
        </ReferenceField>
        <SelectInput source="has_trouble" choices={createIsExceptionFlow()} validate={validateRequired} />
        <TextInput
          source="after_delivery_inspection_memo"
          parse={preventSendNull}
          validate={validateMemo}
          fullWidth
          multiline
        />
        <ArrayInput source="after_delivery_inspection_attachments">
          <SimpleFormIterator disableReordering={true}>
            <AttachmentFileInput addLabel={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
