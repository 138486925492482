import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";

import { UnassignedDeliveryContent } from "../api/unassigned_delivery_contents";
import { Grade } from "../api/common";

const useStyles = makeStyles({
  table: {
    width: 400,
  },
  caption: {
    color: grey.A700,
  },
});

export const UnassignedDeliveryContentTable = (props: {
  unassignedDeliveryContents: UnassignedDeliveryContent[];
  inspectionResultGrades: (Grade | null)[];
}) => {
  const translate = useTranslate();
  const { inspectionResultGrades, unassignedDeliveryContents } = props;
  const styles = useStyles();

  const gradeNames: Array<{ id: null | number; name: string }> = inspectionResultGrades
    .flatMap((grade) => (grade === null ? [] : grade))
    .map((grade) => {
      return {
        id: grade.id,
        name: grade.name,
      };
    });
  gradeNames.push({
    id: null,
    name: translate("ui.value.default_grade"),
  });

  if (unassignedDeliveryContents.length === 0) {
    return (
      <Box>
        <Typography variant="caption" className={styles.caption}>
          {translate("ui.individual_deliveries.unassigned_delivery_content")}
        </Typography>
        <Box my={2}>
          <Typography>{translate("ui.individual_deliveries.no_unassigned_delivery_content")}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="caption" className={styles.caption}>
        {translate("ui.individual_deliveries.unassigned_delivery_content")}
      </Typography>
      <Box my={2}>
        <TableContainer className={styles.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{translate("resources.amends.fields.grade")}</TableCell>
                <TableCell>{translate("resources.amends.fields.package_count")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unassignedDeliveryContents.map((udc) => {
                const { grade_id, package_count } = udc;
                const gradeName = gradeNames.find((gn) => gn.id === grade_id)?.name;
                return (
                  <TableRow key={gradeName}>
                    <TableCell component="th" scope="row">
                      {gradeName}
                    </TableCell>
                    <TableCell>{package_count}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
