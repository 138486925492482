import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  NumberField,
  SelectField,
  SelectInput,
  SingleFieldList,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import CustomizableDatagrid from "ra-customizable-datagrid";
import { ExistsSpecialConditionsField } from "../components/exists_special_conditions_field";
import { formatStringAsCurrency } from "../utils/locale";
import { UndecidedOrFreeInput } from "../components/UndecidedOrFreeInput";
import { InvoiceRegistrationStatusField } from "../components/InvoiceRegistrationStatusField";
import { csvExporter } from "../infrastructure/csvExporter";

const statuses = ["closed", "done", "canceled"];
const createStatusChoices = () =>
  statuses.map((status) => ({
    id: status,
    name: `resources.adjustment_deals.fields.statuses.${status}`,
  }));

function getValueOrUndecided(attr: string, formatFunction?: (val: any) => any) {
  const obj = attr.split(".")[0];
  const prop = attr.split(".")[1];

  return (record: any) => {
    if (formatFunction) {
      if (prop && record[obj]) {
        return record[obj][prop] == null ? "未確定" : formatFunction(record[obj][prop]);
      }

      return record[attr] == null ? "未確定" : formatFunction(record[attr]);
    } else {
      if (prop && record[obj]) {
        return record[obj][prop] == null ? "未確定" : record[obj][prop];
      }

      return record[attr] == null ? "未確定" : record[attr];
    }
  };
}

const AdjustmentDealFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="status" choices={createStatusChoices()} />
    <TextInput source="producer_company_name" />
    <TextInput source="annum_name" />
    <TextInput source="prefecture_name" />
    <UndecidedOrFreeInput source="region_name" />
    <UndecidedOrFreeInput source="brand_name" />
    <UndecidedOrFreeInput source="grade_name" />
    <UndecidedOrFreeInput source="unit_price" />
  </Filter>
);

export const AdjustmentDealList = (props: any) => {
  return (
    <List
      {...props}
      filters={<AdjustmentDealFilter />}
      sort={{ field: "id", order: "DESC" }}
      exporter={csvExporter(props)}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="id" />
        <SelectField source="status" choices={createStatusChoices()} sortable={false} />
        <TextField source="producer.company_name" sortable={false} />
        <TextField source="annum.name" sortable={false} />
        <TextField source="prefecture.name" sortable={false} />
        <FunctionField
          source="region.name"
          render={getValueOrUndecided("region.name")}
          sortable={false}
          textAlign="left"
          displayName="TextField"
        />
        <FunctionField
          source="brand.name"
          render={getValueOrUndecided("brand.name")}
          sortable={false}
          textAlign="left"
          displayName="TextField"
        />
        <FunctionField
          source="grade.name"
          render={getValueOrUndecided("grade.name")}
          sortable={false}
          textAlign="left"
          displayName="TextField"
        />
        <FunctionField
          source="packaging_form.name"
          render={getValueOrUndecided("packaging_form.name")}
          sortable={false}
          textAlign="left"
          displayName="TextField"
        />
        <FunctionField
          source="trading_units"
          render={getValueOrUndecided("trading_units")}
          sortable={false}
          textAlign="right"
          displayName="TextField"
        />
        <FunctionField
          source="trading_sacks"
          render={getValueOrUndecided("trading_sacks")}
          sortable={false}
          textAlign="right"
          displayName="TextField"
        />
        <FunctionField
          source="unit_price"
          render={getValueOrUndecided("unit_price", formatStringAsCurrency)}
          sortable={false}
          textAlign="right"
          displayName="NumberField"
        />
        <FunctionField
          source="trading_volume_in_yen"
          render={getValueOrUndecided("trading_volume_in_yen", formatStringAsCurrency)}
          sortable={false}
          textAlign="right"
          displayName="NumberField"
        />
        <ExistsSpecialConditionsField source="special_condition_ids" sortable={false} />
        <DateField source="decision_date" locales={"ja"} />
      </CustomizableDatagrid>
    </List>
  );
};

export const AdjustmentDealShow = (props: any) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="ui.adjustment_deals.tabs.info">
          <TextField source="id" />
          <SelectField source="status" choices={createStatusChoices()} />
          <DateField source="decision_date" locales={"ja"} />
          <TextField source="producer.company_name" />
          <InvoiceRegistrationStatusField source="producer.invoice_registration_status" />
          <DateField source="created_at" locales={"ja"} showTime={true} />
        </Tab>
        <Tab path="contract" label="ui.adjustment_deals.tabs.contract">
          <TextField source="annum.name" />
          <TextField source="prefecture.name" />
          <FunctionField source="region.name" render={getValueOrUndecided("region.name")} />
          <FunctionField source="brand.name" render={getValueOrUndecided("brand.name")} />
          <FunctionField source="grade.name" render={getValueOrUndecided("grade.name")} />
          <FunctionField source="packaging_form.name" render={getValueOrUndecided("packaging_form.name")} />
          <FunctionField source="trading_units" render={getValueOrUndecided("trading_units")} />
          <FunctionField source="trading_sacks" render={getValueOrUndecided("trading_sacks")} />
          <FunctionField source="trading_kg" render={getValueOrUndecided("trading_kg")} />
          <FunctionField source="package_count" render={getValueOrUndecided("package_count")} />
          <FunctionField source="unit_price" render={getValueOrUndecided("unit_price", formatStringAsCurrency)} />
          <FunctionField
            source="trading_volume_in_yen"
            render={getValueOrUndecided("trading_volume_in_yen", formatStringAsCurrency)}
          />
          <FunctionField source="storage_period.name" render={getValueOrUndecided("storage_period.name")} />
          <FunctionField
            source="storage_period.bonus_in_yen"
            render={getValueOrUndecided("storage_period.bonus_in_yen", formatStringAsCurrency)}
          />
          <ArrayField source="grade_penalties">
            <Datagrid>
              <NumberField source="grade.name" />
              <NumberField source="penalty_in_yen" emptyText="受け取らない" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="special_conditions">
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="special_conditions_memo" component="pre" />
          <TextField source="adjustment_memo" component="pre" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
