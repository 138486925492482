import { unparse } from "papaparse";

import {
  Translator,
  translateHeaderRow,
  translateValue,
  formatDateColumns,
  sliceObjectArrayColumn,
  sliceObjectColumn,
  translateGradePenalties,
  translateColumn,
} from "../utils/csv";

type Exporter = (data: any[]) => Promise<string>;

export const getConverter = (resource: string, translate: Translator): Exporter => {
  switch (resource) {
    case "adjustment_deals":
      return getAdjustmentDealConverter(translate);
    default:
      throw new Error("unknown resource");
  }
};

const ADJUSTMENT_DEAL_COLUMNS = [
  "id",
  "status",
  "producer.company_name",
  "annum.name",
  "prefecture.name",
  "region.name",
  "brand.name",
  "grade.name",
  "packaging_form.name",
  "trading_units",
  "trading_sacks",
  "unit_price",
  "trading_volume_in_yen",
  "special_conditions",
  "special_conditions_memo",
  "decision_date",
  "storage_period.name",
  "storage_period.bonus_in_yen",
  "grade_penalties",
  "adjustment_memo",
];

const getAdjustmentDealConverter = (translate: Translator): Exporter => {
  return async (data: any[]) => {
    let converted = data.concat([]);

    converted = translateValue(converted, "status", translate, "adjustment_deal_status");

    converted = sliceObjectColumn(converted, ["producer", "company_name"]);
    converted = sliceObjectColumn(converted, ["annum", "name"]);
    converted = sliceObjectColumn(converted, ["prefecture", "name"]);
    converted = sliceObjectColumn(converted, ["region", "name"]);
    converted = translateColumn(converted, "region.name", "", "未確定");
    converted = sliceObjectColumn(converted, ["brand", "name"]);
    converted = translateColumn(converted, "brand.name", "", "未確定");
    converted = sliceObjectColumn(converted, ["grade", "name"]);
    converted = translateColumn(converted, "grade.name", "", "未確定");
    converted = sliceObjectColumn(converted, ["packaging_form", "name"]);
    converted = translateColumn(converted, "packaging_form.name", "", "未確定");

    converted = translateColumn(converted, "trading_units", null, "未確定");
    converted = translateColumn(converted, "trading_sacks", null, "未確定");
    converted = translateColumn(converted, "unit_price", null, "未確定");
    converted = translateColumn(converted, "trading_volume_in_yen", null, "未確定");

    converted = sliceObjectColumn(converted, ["storage_period", "name"]);
    converted = translateColumn(converted, "storage_period.name", "", "未確定");
    converted = sliceObjectColumn(converted, ["storage_period", "bonus_in_yen"]);
    converted = translateColumn(converted, "storage_period.bonus_in_yen", null, "0");
    converted = translateGradePenalties(converted);

    converted = sliceObjectArrayColumn(converted, "grade_penalties", ["name", "penalty_in_yen"], "grade_id");
    converted = sliceObjectArrayColumn(converted, "special_conditions", ["display_text"], "id");

    converted = formatDateColumns(converted, ["decision_date"]);

    converted = translateHeaderRow(converted, "adjustment_deals", translate, ADJUSTMENT_DEAL_COLUMNS);
    return unparse(converted);
  };
};
