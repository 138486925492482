import { Prefecture } from "../api/prefectures";
import { HacobellPreferredVehicle } from "../api/individual_deliveries";
import { PackagingForm } from "../api/common";
import Tariff from "../json/tariff.json";

type TariffRecord = {
  from: string;
  to: string;
  vehicle: string;
  price: number;
};

// 1俵あたりのkg
const KG_PER_TAWARA = 60;

// 運送量周りは税率固定
const TAX_RATE = 10;

// 取次料
const AGENCY_FEE = 2000;

function convertVehicleKeyToName(vehicle: HacobellPreferredVehicle): string | null {
  switch (vehicle) {
    case "ten_ton": {
      return "10t車両";
    }
    default: {
      return null;
    }
  }
}

export function findBasePrice(from: Prefecture, to: Prefecture, vehicle: HacobellPreferredVehicle): number | null {
  const tariff: TariffRecord[] = Tariff;
  const vehicleName = convertVehicleKeyToName(vehicle);
  const targetTariffRecords = tariff.filter(
    (record) => record.from === from.name && record.to === to.name && record.vehicle === vehicleName
  );
  if (targetTariffRecords.length !== 1) {
    return null;
  }
  return targetTariffRecords[0].price;
}

function calcTax(price: number) {
  return (price * (100 + TAX_RATE)) / 100;
}

export function calcAgencyFee() {
  return calcTax(AGENCY_FEE);
}

export function calcTotalPrice(basePrice: number) {
  return calcTax(basePrice + AGENCY_FEE);
}

export function calcPricePerTawara(totalPrice: number, packageCount: number, packagingForm: PackagingForm) {
  const { amount_in_kg } = packagingForm;
  const tawaraPerPackage = amount_in_kg / KG_PER_TAWARA;
  const totalTawara = packageCount * tawaraPerPackage;
  return totalPrice / totalTawara;
}
