import {
  TASK_KEYS_OF_ADVANCE_CONTRACT,
  TASK_KEYS_OF_SPOT_CONTRACT,
  TaskKeyOfAdvanceContract,
  TaskKeyOfSpotContract,
} from "../api/pending_tasks";

export const createStateChoices = () =>
  [true, false].map((state) => ({
    id: state,
    name: `commons.states.${state ? "active" : "inactive"}`,
  }));

export const createIsDeletedChoices = () =>
  [true, false].map((state) => ({
    id: state,
    name: `commons.is_deleted.${state ? "is_deleted" : "is_not_deleted"}`,
  }));

export const createPublishStateChoices = () =>
  ["waiting", "published", "done"].map((state) => ({
    id: state,
    name: `commons.publish_statuses.${state}`,
  }));

export const createAdjustUnitPriceChoices = () =>
  [true, false].map((state) => ({
    id: state,
    name: `commons.can_adjust_unit_price.${state ? "can" : "cannot"}`,
  }));

export const createInspectionResponsibilityChoices = () =>
  ["not_needed", "self", "request"].map((state) => ({
    id: state,
    name: `commons.inspection_responsibilities.${state}`,
  }));

export const createDeliveryTypeChoices = () =>
  ["hacobell", "self_delivery"].map((state) => ({
    id: state,
    name: `commons.delivery_types.${state}`,
  }));

export const createDeliveryStatusChoices = () =>
  ["draft", "waiting_for_arrangement", "waiting_for_inspection", "done", "cancelled", "cancelled_by_deal"].map(
    (state) => ({
      id: state,
      name: `commons.delivery_statuses.${state}`,
    })
  );

// ハコベルのAPIで定義されているが米市場の仕様上使わないものはコメントアウトしている
export const createHacobellPreferredVehicleChoices = () =>
  [
    // "light",
    // "one_ton",
    // "two_ton",
    // "four_ton",
    "ten_ton",
    // "trailer",
  ].map((item) => ({
    id: item,
    name: `commons.hacobell_preferred_vehicles.${item}`,
  }));

export const createHacobellOptionChoices = () =>
  [
    "stack",
    "waypoint",
    "additional",
    "two_men",
    "other",
    "refrigerator",
    "freezer",
    "tail_gate_lift",
    "air_conditioner",
    "air_suspension",
    "stanchion",
    "three_men",
    "sundays_and_holidays",
    "night_time",
    "insurance_without_tax",
    "campaign_discount",
  ].map((item) => ({
    id: item,
    name: `commons.hacobell_options.${item}`,
  }));

export const createTaskKeys = () => {
  type IdName = {
    id: TaskKeyOfAdvanceContract | TaskKeyOfSpotContract;
    name: string;
  };

  const advanceContractKeys: IdName[] = Object.values(TASK_KEYS_OF_ADVANCE_CONTRACT).map((key) => ({
    id: key,
    name: `commons.task_keys.${key}`,
  }));

  const spotContractKeys: IdName[] = Object.values(TASK_KEYS_OF_SPOT_CONTRACT).map((key) => ({
    id: key,
    name: `commons.task_keys.${key}`,
  }));

  return advanceContractKeys.concat(spotContractKeys);
};

export const createIsExceptionFlow = () => {
  return [false, true].map((state) => {
    const id = state ? "ng" : "ok";
    return {
      id: state,
      name: `commons.inspection_result.${id}`,
    };
  });
};

export const createHasProducerReviewed = () => {
  return [false, true].map((state) => {
    const id = state ? "done" : "not_yet";
    return {
      id: state,
      name: `commons.has_producer_reviewed.${id}`,
    };
  });
};

const bankAccountTypes = ["futsuu", "touza", "chochiku"];
export const createBankAccountTypes = () =>
  bankAccountTypes.map((accountType) => ({
    id: accountType,
    name: `commons.bank_account_types.${accountType}`,
  }));

const paymentStatuses = [
  "provisional",
  "waiting_for_billing",
  "waiting_for_payment_report",
  "waiting_for_payment_confirmation",
  "waiting_for_transfer",
  "done",
];
export const createPaymentStatuses = () =>
  paymentStatuses.map((item) => ({
    id: item,
    name: `commons.payment_statuses.${item}`,
  }));

const deliveryCategories = ["hacobell"];
export const createDeliveryCategories = () =>
  deliveryCategories.map((item) => ({
    id: item,
    name: `commons.delivery_categories.${item}`,
  }));

const deliverySubcategories = [
  "shipping",
  "options",
  "agency",
  "cancellation_fee",
  "parking_freight_handling_waiting",
  "other",
];
export const createDeliverySubcategories = () =>
  deliverySubcategories.map((item) => ({
    id: item,
    name: `commons.delivery_subcategories.${item}`,
  }));

export const createIsProductAdjustments = () =>
  [true, false].map((item) => ({
    id: item,
    name: `commons.is_product_adjustment.${item ? "yes" : "no"}`,
  }));

const hacobellStatuses = [
  "circulated",
  "seeking",
  "opened",
  "requesting",
  "unchartered",
  "charter_not_fixed",
  "chartered",
  "in_transit",
  "shipment_delivered",
  "shipment_completed",
  "canceled",
  "rejected",
  "closed",
];
export const createHacobellStatuses = () =>
  hacobellStatuses.map((status) => ({
    id: status,
    name: `commons.hacobell_statuses.${status}`,
  }));

const adjustmentDealStatuses = ["closed", "done", "canceled"];
export const createAdjustmentDealStatusesChoices = () =>
  adjustmentDealStatuses.map((statues) => ({
    id: statues,
    name: `commons.adjustment_deal_statuses.${statues}`,
  }));
