import { downloadCSV, useListContext, useTranslate } from "ra-core";
import { getConverter } from "./csvConverter";

export const csvExporter = (props: any) => {
  const { resource } = useListContext(props);
  const translate = useTranslate();

  return async (data: any) => {
    const filename = translate(`resources.${resource}.filename`);
    const convert = getConverter(resource, translate);

    const csvString = await convert(data);
    const buffer = new Buffer("\ufeff" + csvString);
    downloadCSV(buffer, filename);
  };
};
