import { DateTime } from "luxon";

import { createAdjustmentDealStatusesChoices, createStateChoices } from "../components/choices";
import { Grade } from "../api/common";

// react-adminの翻訳関数の型
export type Translator = (key: string, options?: any) => string;

/**
 * ISO8601形式の日付文字列をExcelで扱いやすい表記に変換する
 * 日付が入っていない場合は何もせず空白のままになる
 *
 * @param targets オブジェクトの配列
 * @param columns ISO8601形式の日付の文字列を持つキー
 */
export const formatDateColumns = (targets: any[], columns: string[]) => {
  return targets.map((target) => {
    columns.forEach((column) => {
      if (target[column]) {
        target[column] = DateTime.fromISO(target[column]).toFormat("yyyy/M/d");
      }
    });
    return target;
  });
};

/**
 * boolean値や定数値で持っている値を一括して翻訳する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param columns 項目のキーの配列
 * @param translate react-adminの翻訳関数
 */
export const translateValues = (targets: any[], columns: string[], translate: Translator) => {
  return columns.reduce((results, column) => {
    return translateValue(results, column, translate);
  }, targets);
};

/**
 * boolean値や定数値で持っている値を翻訳する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param key 項目のキー
 * @param translate react-adminの翻訳関数
 * @param choicesKey 選択肢を作成するときのキー
 */
export const translateValue = (targets: any[], key: string, translate: Translator, choicesKey?: string) => {
  let choices: any[] = [];
  switch (choicesKey || key) {
    case "adjustment_deal_status":
      choices = createAdjustmentDealStatusesChoices();
      break;
    case "is_active":
      choices = createStateChoices();
      break;
    default:
      break;
  }
  return targets.map((target) => {
    const item = choices.find((choice) => choice.id === target[key]);
    target[key] = item ? translate(item.name) : "エラー";
    return target;
  });
};

/**
 * キー名を翻訳する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param resource リソース名
 * @param translate react-adminの翻訳関数
 * @param orderedKeys 指定した項目順に表示する場合
 */
export const translateHeaderRow = (targets: any[], resource: string, translate: Translator, orderedKeys?: string[]) => {
  if (targets.length === 0) {
    return targets;
  }
  const keys = orderedKeys ? orderedKeys : Object.keys(targets[0]);
  return targets.map((target) => {
    return keys.reduce((obj: { [key: string]: any }, key) => {
      const translateKey = `resources.${resource}.fields.${key}`;
      const translatedLabel = translate(translateKey);
      const label = translatedLabel !== translateKey ? translatedLabel : key;
      obj[label] = target[key];
      return obj;
    }, {});
  });
};

/**
 * オブジェクトの指定されたキーの値を取得する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param properties オブジェクト名.プロパティを分解した文字列配列
 */

export const sliceObjectColumn = (targets: any[], properties: string[]) => {
  return targets.map((target) => {
    const [object, property] = properties;
    const column = properties.join(".");

    target[column] = target[object] && target[object][property] ? target[object][property] : "";
    return target;
  });
};

/**
 * オブジェクトの指定されたキーを取得する。指定があればマスタデータのnameプロパティで置き換えも行う
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param column オブジェクトのカラム名
 * @param properties オブジェクトから取得したいプロパティ名
 * @param key マスタで置き換える場合のキーとなるプロパティ名
 * @param relations 置換したいマスタの配列
 */
export const sliceObjectArrayColumn = (
  targets: any[],
  column: string,
  properties: string[],
  key?: string,
  relations?: any[]
) => {
  return targets.map((target) => {
    if (!target[column]) {
      target[column] = "";

      return target;
    }

    const labels = target[column].map((object: any) => {
      const label = properties.map((property) => {
        if (relations && key && key === property) {
          const item = relations.find((record) => record.id === object[property]);
          return item === undefined ? "" : item.is_active ? item.name : item.name + "（無効）";
        }
        return object[property];
      });

      return label.join("：");
    });
    target[column] = labels.join("、");

    return target;
  });
};

/**
 * 等級格差減額の「受け取らない」として翻訳する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param column オブジェクトのカラム名
 */
export const translateGradePenalties = (targets: any[], column: string = "grade_penalties") => {
  return targets.map((target) => {
    target[column].forEach(
      (value: { grade_id: number; penalty_in_yen: null | number | string; name?: string; grade: Grade }) => {
        if (value["penalty_in_yen"] === null) {
          value["penalty_in_yen"] = "受け取らない";
        } else {
          value["penalty_in_yen"] = `${value["penalty_in_yen"]}円`;
        }
        if (value["grade"] && value["grade"]["name"]) {
          value["name"] = value["grade"]["name"];
        }
      }
    );

    return target;
  });
};

/**
 * 条件に合う値の場合、指定された文言に翻訳する
 *
 * @param targets 変換対象のオブジェクトの配列
 * @param column オブジェクトのカラム名
 * @param value 条件値
 * @param translation 翻訳する文字列
 */
export const translateColumn = (targets: any[], column: string, value: string | null, translation: string) => {
  return targets.map((target) => {
    if (target[column] === value) {
      target[column] = translation;
    }

    return target;
  });
};
