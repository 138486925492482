import React from "react";
import { useListContext, useTranslate } from "react-admin";

import clsx from "clsx";
import { TextField as MuiTextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const PREFIX = "RaAutocompleteInput";
const AutocompleteInputClasses = {
  textField: `${PREFIX}-textField`,
};

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${AutocompleteInputClasses.textField}`]: {
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(1),
    backgroundColor: "rgb(0, 0, 0, 0.04)",
    '& label:not([data-shrink="true"])': {
      transform: "translate(12px, 17px) scale(1)",
    },
  },
}));

interface Option {
  name: string;
}

const handleSearchChange = (setFilters: any, filterValues: any, source: string) => {
  return (_: any, newValue: any) => {
    if (newValue && (newValue.id === "undecided" || newValue === "未確定")) {
      setFilters({ ...filterValues, [source]: "undecided" }, null);
    } else if (typeof newValue === "string") {
      setFilters({ ...filterValues, [source]: newValue }, null);
    } else {
      setFilters({ ...filterValues, [source]: "" }, null);
    }
  };
};

export const UndecidedOrFreeInput = (props: any) => {
  const { filterValues, setFilters } = useListContext();
  const { source } = props;
  const translate = useTranslate();
  const label = translate(`resources.adjustment_deals.fields.${source}`);
  const searchOptions = [{ id: "undecided", name: "未確定" }];

  return (
    <StyledAutocomplete
      className={clsx("ra-input", `ra-input-${source}`)}
      freeSolo
      disableClearable
      options={searchOptions}
      getOptionLabel={(option: unknown) => {
        if (typeof option === "string") {
          return option;
        }
        // オブジェクトで name プロパティが存在することを確認する
        if (typeof option === "object" && option !== null && "name" in option) {
          return (option as Option).name;
        }
        return "";
      }}
      onChange={handleSearchChange(setFilters, filterValues, source)}
      value={filterValues[source] === "undecided" ? searchOptions[0] : filterValues[source] || ""}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={label}
          className="RaAutocompleteInput-textField"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              const input = event.target as HTMLInputElement;
              handleSearchChange(setFilters, filterValues, source)(event, input.value);
            }
          }}
        />
      )}
    />
  );
};
